import React, {lazy, Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ProgressInfinite} from '@brandwatch/axiom-components';
import {useAuth} from '@brandwatch/oidc-spa-client-react'

const App = lazy(() =>
  import(/* webpackChunkName: 'app' */ './App.jsx')
);

export default function Main() {
  const [hasAuthed, setHasAuthed] = useState(false);
  const {getAccessToken} = useAuth();

  useEffect(() => {
    const getToken = async () => {
      try {
        await getAccessToken();
        setHasAuthed(true);
      } catch (e) {
        setHasAuthed(false);
      }
    };
    getToken();
  }, [getAccessToken]);

  return (
    <Suspense fallback={<ProgressInfinite />}>
      <Router>
        {hasAuthed ? <App /> : <ProgressInfinite />}
      </Router>
    </Suspense>
  );
}
