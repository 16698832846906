'use strict';

export default window.env || {
  DASHBOARD_API_BASE_URL: 'http://api.narwhal.vizialocal.io:8081',
  BW_API_SERVER_BASE_URL: 'http://api.stage.brandwatch.com/',
  AUTH_STORE_BASE_URL: 'https://login.stage.brandwatch.net/store',
  MISSION_CONTROL_BASE_URL: 'https://mission-control.stage.brandwatch.net',
  OIDC_BRIDGE_URL: 'https://oidc-proxy.apps.aws.stage.brandwatch.net',
  OIDC_CLIENT_ID: 'api-products-stage-client'
};
