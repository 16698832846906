import React from 'react';
import {render} from 'react-dom';
import {OIDCSPAProvider} from '@brandwatch/oidc-spa-client-react';
import config from './config';
import Main from './components/Main.jsx';
import './index.css';

const MainWrapper = () => {
  return (
    <OIDCSPAProvider
      config={{
        OIDC_BRIDGE_URL: config.OIDC_BRIDGE_URL,
        OIDC_CLIENT_ID: config.OIDC_CLIENT_ID
      }}
    >
      <Main />
    </OIDCSPAProvider>
  );
};

render(<MainWrapper />, document.getElementById('root'));
